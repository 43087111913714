













































import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { dispatch } from "@/store";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {
      loading: false,
      coin_name: undefined,
      tag_name: undefined,
      list: [] as any,
      hash_key: ""
    };
  },
  computed: {
    ...mapGetters({ allow: "allow" }),
    ...mapState({ user: "user" }),
    ...mapState({ app: "app" }),
    ...mapState("app", {
      isCN: "isCN",
      language: "language",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images",
      oss: "oss"
    }),
    ...mapState("tasks", {
      categoryList: "categoryList",
      tagList: "tagList",
      chainList: "chainList"
    }),
    id(): number {
      return Number(this.$route.params.id);
    },
    isAdd(): boolean {
      return !this.id;
    },
    editor(): any {
      return (this.$refs.editor as any).quill;
    },
    editorOption(): any {
      return {
        modules: {}
      };
    }
  },
  methods: {
    onUploadClick() {
      document.getElementById("file")?.click();
    },
    onUpload(e: any) {
      const file = e.target.files[0];
      if (file) {
        new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onerror = reject;
          fileReader.onload = e => {
            try {
              const data = JSON.parse(e.target?.result as string);
              if (data) {
                resolve(data);
              } else {
                reject();
              }
            } catch (error) {
              reject(error);
            }
          };
          fileReader.readAsText(file);
        })
          .then(data => {
            console.log("data", data);
            this.list = data;
            e.target.value = null;
          })
          .catch(err => {
            e.target.value = null;
            this.list = [];
            this.$message.info("文件解析失败，请参考模板仔细检查");
            console.log(err);
          });
      }
    },
    onDownload() {
      download(this.oss.task_batch_create_demo);
    },
    onSubmit() {
      const { coin_name, tag_name, list } = this;
      if (!coin_name) {
        this.$message.info("请选择正确的所属项目");
        return false;
      }
      if (!tag_name) {
        this.$message.info("请选择正确的任务标签");
        return false;
      }
      if (!list.length) {
        this.$message.info("请上传正确的模板文件");
        return false;
      }
      this.loading = true;
      dispatch
        .tasksBatchCreate({
          coin_name,
          tag_name,
          data: list
        })
        .then(() => {
          this.loading = false;
          this.$message.info("" + this.$t("task.createSuccess"));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onDelete() {
      this.$confirm({
        title: this.$t("task.deleteConfirm"),
        centered: true,
        okText: "" + this.$t("task.ok"),
        cancelText: "" + this.$t("task.cancel"),
        onOk: () => {
          dispatch
            .tasksBatchDelete({
              hash_key: this.hash_key
            })
            .then(() => {
              this.$message.info("" + this.$t("task.deleteSuccess"));
            });
        }
      });
    }
  },
  mounted() {}
});
